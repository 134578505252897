import { useState } from 'react';

// styles
import classes from './Bio.module.css';


const Bio = () => {

  // small reso show - hide bio
  const [openBio, setOpenBio] = useState(false);

  const openBioSmall = () => {
    setOpenBio(!openBio)
  }


  return ( 
    <div className="fm_wrapper">
      <div className="fm_container">      
        <div className={classes.fm_bio_content}>

          <div className={classes.fm_open_bio_small} onClick={openBioSmall}>
            {!openBio ? "click here to close my Bio" : "click here to open my Bio" }
          </div>

          <div className={`${classes.fm_bio_holder} ${ openBio ? classes.fm_hide_bio : ''}`}>
            {/* bio top */}
            <div className={classes.fm_bio_top}>

              {/* blog top left */}
              <div className={classes.fm_bio_top_left}>
                <img className={classes.fm_bio_img} src="/img/home/francesca_bio.png" alt="Francesca Marretta" />
                <div className={classes.fm_bio_img_desc}>Francesca Marretta <span>Bergamo, 08/2007</span></div>
              </div>

              {/* blog top right */}
              <div className={classes.fm_bio_top_right}>
                <div className={classes.fm_name_title_infos_holder}>
                  <div className={classes.fm_name_title_infos}>
                    <div className={classes.fm_name_title_and}>&</div>
                    <div className={classes.fm_name_title_job}>
                      <div className={classes.fm_name_title}>Francesca <br/>Marretta</div>
                      <div className={classes.fm_name_job}>Communications Specialist <br/>Award winning Journalist</div>
                    </div>
                  </div>
                </div>
                <div className={classes.fm_bio_text}>
                  <p>Francesca Marretta was born in Naples, Italy. <span>She is an Award winning Journalist, collecting the Premio Enzo Baldoni Award in 2011</span>; an award dedicated to the Italian Journalists killed in war zones. Memember of the Ordine dei Giornalisti (Elenco professionisti), the Italian official Journalistic Board, of the International Federation of Journalists and of the think-thank PHAP – Professionals in Humanitarian Assistance and Protection.</p>

                  <p>She speaks Italian, English, French, Russian and a little Arabic.</p>

                  <p>"<span>As a journalist</span>, I like to get my hands dirty, digging up stories that don’t make the headlines, stories of the people and places as I travel the world. Based in the UK, my home is now in Devon, far from Naples where I was born. Proud of being a Neapolitan I grew up in the shadow of Mount Vesuvius and the violence of the Camorra. A place to watch your back and to look out for others. It is a loud and crazy place but its beauty, culture and humanity seems able to bear it all washed clean by the sea. For my work it is my grounding. my school for life."</p>

                  <p>She has worked in UN Peacekeeping missions in Africa as Electoral Adviser and Public Information Officer. As a Journalist she has contributed to <span>Newspapers and Magazines, like Liberazione, Il Mattino,  Il Venerdì di Repubblica, IlSole24ore.com, globalist.it, Pagina99</span>. She has been published the collective book: “Gerusalemme ultimo viaggio” Ets Edizioni.</p>

                  <p>In 2013-2014 Francesca worked as Press Officer for the EU Border Assistance Mission to Libya.</p>

                  <p>Francesca Marretta has lived in Africa and Middle East and has reported from the Occupied Palestinian Territories, Israel, Lebanon, Afghanistan, Pakistan, Egypt, Syria, Rwanda, Mali, Liberia, Libia, Uganda, Burundi, Kenya, Ethiopia, Morocco, United Kingdom and Italy.</p>

                  <p>From the summer of 2014 I will be working in the Horn of Africa. You will hear from me soon. Thanks for stopping by. F.</p>
                </div>
              </div>
            </div>

            {/* bio bottom */}
            <div className={classes.fm_bio_bot}>

              {/* blog bottom left */}
              <div className={classes.fm_bio_bot_left}>
                <div className={classes.fm_bio_text}>
                  <p>Francesca studied Political Science at the Università degli Staudi di Napoli l’Orientale. Among her Post-graduates Studies there are REACT (Expert Assistance and Cooperation for Conflict Prevention Operations, Crisis Management and Post-Conflict Rehabilitation), Helsinki Espana, Madrid, Spain, the International Training Programme for Conflict Management at the Scuola Superiore S.Anna Pisa, Italy as well as International Electoral Trainings.</p>

                  <p>She has worked in UN Peacekeeping missions in Africa as Electoral Adviser and Public Information Officer. As a Journalist she has contributed to <span>Newspapers and Magazines, like Liberazione, Il Mattino,  Il Venerdì di Repubblica, IlSole24ore.com, globalist.it, Pagina99</span>. She has been published the collective book: “Gerusalemme ultimo viaggio” Ets Edizioni.</p>

                  <p>In 2013-2014 Francesca worked as Press Officer for the EU Border Assistance Mission to Libya.</p>

                  <p>Francesca Marretta has lived in Africa and Middle East and has reported from the Occupied Palestinian Territories, Israel, Lebanon, Afghanistan, Pakistan, Egypt, Syria, Rwanda, Mali, Liberia, Libia, Uganda, Burundi, Kenya, Ethiopia, Morocco, United Kingdom and Italy.</p>

                  <p>From the summer of 2014 I will be working in the Horn of Africa. You will hear from me soon. Thanks for stopping by. F.</p>
                </div>
              </div>

              {/* blog bottom right */}
              <div className={classes.fm_bio_bot_right}>
                <img className={classes.fm_bio_img_map} src="/img/home/bio_map_full.png" alt="bio map" />
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
  
export default Bio;