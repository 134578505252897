import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyDVQYjYnT-5DMhNjxusV1nDqcbWOA_0nqY",
    authDomain: "francesca-maretta.firebaseapp.com",
    projectId: "francesca-maretta",
    storageBucket: "francesca-maretta.appspot.com",
    messagingSenderId: "569089441763",
    appId: "1:569089441763:web:1328887fd8a86e9e7daad6",
    measurementId: "G-N0WPR5V8ZZ"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();

export {
    app,
    analytics,
    db,
};
