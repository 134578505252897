import React, {useContext, useState} from "react";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import CustomTable from "./CustomTable";
import AppContext from "../../AppContext";
import Button from "@mui/material/Button";
import {Modal} from "@mui/material";
import ModalForm from "./ModalForm";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function TabPanel(props) {
  const { children, tabValue, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {tabValue === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AdminHomeContent = () => {
  const {blogs, articles, press} = useContext(AppContext)
  const [tabValue, setTabValue] = useState(0);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <section className="fm_wrapper">
      <div className="fm_container">
        <h1>Admin Home</h1>

        <div style={{position: "relative"}}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Blogs" {...a11yProps(0)} />
              <Tab label="Articles" {...a11yProps(1)} />
              <Tab label="Press" {...a11yProps(2)} />
            </Tabs>
          </Box>

          <TabPanel tabValue={tabValue} index={0}>
            <Button onClick={handleOpen} variant="contained" color="info" style={{marginBottom: "16px"}}>Add new Blog</Button>
            <CustomTable rows={blogs} />
          </TabPanel>

          <TabPanel tabValue={tabValue} index={1}>
            <Button onClick={handleOpen} variant="contained" color="info" style={{marginBottom: "16px"}}>Add new Article</Button>
            <CustomTable rows={articles} />
          </TabPanel>

          <TabPanel tabValue={tabValue} index={2}>
            <Button onClick={handleOpen} variant="contained" color="info" style={{marginBottom: "16px"}}>Add new Press</Button>
            <CustomTable rows={press} />
          </TabPanel>
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ModalForm />
          </Box>
        </Modal>
      </div>
    </section>
  )
}

export default AdminHomeContent
