// styles
import classes from './Heading.module.css';

const Heading = () => {
  return ( 
    <div className="fm_wrapper">
      <div className="fm_container">      
        <div className={classes.fm_heading_content}>

          {/* heading left side */}
          <div className={classes.fm_heading_left}>
            <div className={classes.fm_heading_image_holder}>
              <img className={classes.fm_heading_img_bg} src="/img/home/heading_img_dots.png" alt="dots" />
              <div className={classes.fm_heading_img_holder}>
                <img className={classes.fm_heading_img} src="/img/home/foto_profilo.png" alt="Francesca Marretta" />
              </div>
            </div>
          </div>

          {/* heading right side */}
          <div className={classes.fm_heading_right}>
            <div className={classes.fm_name_title_infos_holder}>
              <div className={classes.fm_name_title_infos}>
                <div className={classes.fm_name_title_and}>&</div>
                <div className={classes.fm_name_title_job}>
                  <div className={classes.fm_name_title}>Francesca <br/>Marretta</div>
                  <div className={classes.fm_name_job}>Communications Specialist <br/>Award winning Journalist</div>
                </div>
              </div>
            </div>
            <div className={classes.fm_social_networks_logo_socials}>
              <div className={classes.fm_social_networks_logo}>
                <img src="/img/home/logo_white.svg" alt="logo white" />
              </div>
            </div>
          </div>

          {/* scroll icon */}
          <div className={classes.fm_scroll_icon_holder}>
            <img src="/img/home/scroll.svg" alt="tw logo" />
            <div>Scroll</div>
          </div>
        </div>
      </div>
    </div>
  )
}
  
export default Heading;