import { Link } from 'react-router-dom';

// styles
import classes from './Footer.module.css';

const Footer = () => {
  return ( 
    <section className={classes.fm_footer}>
      <div className="fm_container">
        <div className={classes.fm_footer_content}>

          <div className={classes.fm_footer_left}>

            <div className={classes.fm_footer_col}>
              <div className={classes.fm_footer_col_title}>Francesca Marretta:</div>
              <p className={classes.fm_footer_col_content}>Benvenuti nel sito ufficiale di Francesca Marretta. Spero di potervi sempre fornire un informazione reale del mondo.</p>
              <p>@FrancescaMarretta All Rights reserved</p>
            </div>

          </div>

          <div className={classes.fm_footer_right}>

            <div className={classes.fm_footer_col}>
              <div className={classes.fm_footer_col_title}>Navigazione</div>
              <ul>
                {/*<li><Link to="/my-work-as-journalist">My work as journalist</Link></li>*/}
                {/*<li><Link to="/press-officer">My work as a press officer</Link></li>*/}
                {/*<li><Link to="/blog">Blog</Link></li>*/}
                <li><Link to="/home">Home</Link></li>
              </ul>
            </div>

            <div className={classes.fm_footer_col}>
              <div className={classes.fm_footer_col_title}>Contatti</div>
              <p><a href="mailto:info@francescamarretta.com">info@francescamarretta.com</a></p>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}
  
export default Footer;
