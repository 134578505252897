import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

// styles
import classes from './Navigation.module.css';



const Navigation = () => {

  const [showNav, setShowNav] = useState(false);

  const navigation = () => {
    setShowNav(!showNav);
  }

  return ( 
    <div className="fm_wrapper">
      <div className="fm_container">
        <div className={classes.fm_nav_holder}>
          <div className={classes.fm_nav_open}>
            <img src="/img/open_nav.svg" alt="open nav" onClick={navigation} />
          </div>
          
          <nav className= {`${classes.fm_nav} ${showNav && classes.fm_nav_open}`}>
            <div className={classes.fm_nav_content}>
              <div className={classes.fm_nav_close}>
                <img src="/img/close_nav.svg" alt="close nav" onClick={navigation} />
              </div>
              <div className={classes.fm_nav_menu_text}>MENU</div>
              <div className={classes.fm_nav_items}>
                <ul>
                  <li>
                    <NavLink activeClassName={classes.active} to='/home'  onClick={navigation}>HOME</NavLink>
                  </li>
                  {/*<li>*/}
                  {/*  <NavLink activeClassName={classes.active} to='/my-work-as-journalist'  onClick={navigation}>MY WORK AS A JOURNALIST</NavLink>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <NavLink activeClassName={classes.active} to='/press-officer'  onClick={navigation}>PRESS OFFICER</NavLink>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <NavLink activeClassName={classes.active} to='/blog'  onClick={navigation}>BLOG</NavLink>*/}
                  {/*</li>*/}
                </ul>
                <div className={classes.fm_contact_info}>
                  <div className={classes.fm_contact}>
                    <div className={classes.fm_contact_text}>CONTACT MAIL:</div>
                    <div className={classes.fm_contact_mail}>
                      <a href="mailto:info@francescamarretta.com">info@francescamarretta.com</a>
                    </div>
                  </div>
                  <div className={classes.fm_social_networks}>
                    <div className={classes.fm_social_networks_support_msg}>
                      thank you all <br/> for the support
                    </div>
                    <div className={classes.fm_social_networks_logo_socials}>
                      <div className={classes.fm_social_networks_logo}>
                        <NavLink to='/' onClick={navigation}>
                          <img src="/img/logo_black.svg" alt="logo black" />
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  )
}
  
export default Navigation;
